@import './assets/sass/theme.scss';

body {
    user-select: none;
}

body.no-focus-outline :focus {
    outline: none;
}

#rootdiv {
    overflow: hidden;
}

input[type=file] {
    display: none;
}

/* Let's get this party started */
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar:horizontal {
    height: 8px;
}

::-webkit-scrollbar-corner {
    background-color: $darker-1;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px $darker-3;
    background-color: $lighter-1;
    border-radius: 10px;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border-radius: 10px;
	background: $lighter-4;
    box-shadow:  0 0 6px $darker-3;
}
::-webkit-scrollbar-thumb:window-inactive {
	background: $lighter-4;
}

.form-group, .object-wrapper {
    margin-bottom: 1rem;

    .field-description {
        margin-bottom: 0.25rem;
    }

    &.is-invalid {
        .invalid-feedback {
            display: block;
        }

        .form-control {
            border: solid 1px #db7272;
        }
    }

    &.is-valid {
        .valid-feedback {
            display: block;
        }

        .form-control {
            border: solid 1px #cfcecd;
        }
    }

    .rc-checkbox {
        margin-left: 0.5em;
    }

    .slider-value {
        font-weight: 500;
        font-size: 90%;
    }
}

.skip-button a {
    position: absolute;
    right: -10000px;
    top: 0px;
    width: 0px;
    height: 0px;
    overflow: hidden;
    &:focus {
        position: fixed;
        left: 50px;
        top: 2px;
        background-color: #7cbb00;
        display: flex;
        color: white;
        font-size: small;
        align-items: center;
        justify-content: center;
        border: 1px solid white;
        border-radius: 5px;
        min-width: 200px;
        min-height: 28px;
        transition: top .3s ease;
        overflow: auto;
        outline-color: none;
        z-index: 100;
    }
}

.text-sm {
    font-size: 14px;
}

@import "../../assets/sass/theme.scss";

.model-select {
  margin: 5px 0 5px 0;
}

.predict {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  height: 10px; // Not sure why it helps
}

.predict-main {
  position: relative;
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  > i {
    font-size: 36pt;
  }
}

.predict-sidebar {
  height: 100%;
  display: flex;
  .prebuilt-type-dropdown {
    min-width: 130px;
    width: 100%;
    margin-bottom: 10px;
    > span {
      width: 100%;
    }
  }
}

.input-disabled {
  color: #bbb;
  background-color: white;
}

.dummyInputFile {
  color: #757575;
  border: 1px;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 2px;
  width: 100%;
  flex: 1;
}

.seperator {
  margin: 20px 10px;
  position: relative;
  background-color: inherit;
  width: 45%;
  display: inline-block;
}

.seperator:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  border-top: 1px solid white;
  background: white;
  width: 100%;
  transform: translateY(-50%);
}

.sourceDropdown {
  width: 95px;
}

.predict-button {
  margin-bottom: 20px;
}

.no-models-warning {
  margin-top: 8px;
}

.model-selection-container {
  padding-top: 8px;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
}

.model-selection-header {
  margin-bottom: 4px;
}

.model-selection-info-header {
  margin-bottom: 0px;
}

.model-selection-info-key {
  font-size: 12px;
  color: #d1d1d1;
  min-width: 80px;
  float: left;
}

.model-selection-info-value {
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  color: #d1d1d1;
  float: left;
}

.add-row-button_container {
    margin-left: 1.5rem;
    margin-bottom: 3rem;
    margin-top: 1rem;

}

.table-view-container {
    td, th {
        text-align: center;
        vertical-align: middle;
        padding: 0.12rem 0.25rem;
    }
    overflow: auto;
    padding-bottom: .5rem;
    .column_header {
        min-width: 130px;
        max-width: 200px;
        background-color: $lighter-3;
        border: 1px solid grey;
        text-align: center;
        padding: .125rem .25rem;
    }
    .row_header {
        min-width: 100px;
        max-width: 200px;
        border: 1px solid grey;
        background-color: $lighter-3;
        text-align: center;
        padding: .125rem .5rem;
    }
    .empty_header {
        border: 1px solid grey;
        background-color: $lighter-3;

    }
    .table-cell {
        text-align: center;
        background-color: $darker-3;
        color: rgba(255, 255, 255, 0.75);
        &:hover {
            background-color: $lighter-1;
        }
        border: 1px solid grey;

    }
    .hidden {
        border: none;
        background-color: transparent;
        text-align: center;
        color: rgba(255, 255, 255, 0.45);
        min-width: 12px;
        max-width: 48px;
        padding-right: 0.3rem;
    }
  }

.p-3 + .separator-right-pane-main,
.separator-right-pane-main + .p-3 {
  margin-top: -15px !important;
}  

.page-number {
  position: absolute;
  width: 200px;
  bottom: 0;
  left: 50%;
  margin-left: -100px;
  color: white;
  text-shadow:
     1px  1px 0 #000,
    -1px -1px 0 #000,
     1px -1px 0 #000,
    -1px  1px 0 #000,
     1px  1px 0 #000;
  text-align: center;
  font-size: 18px;
  font-family: fantasy;
}

.toolbar-btn {
  margin: 5px;
  width: 48px;
  height: 48px;
  outline: none;
  background-color: $darker-1;
  border: solid 1px $lighter-2;
  color: rgb(0, 161, 241);
  z-index: 10;

  &:hover, &.active {
    background-color: $darker-2;
    border: solid 1px $lighter-3;
  }

  &:active {
      background-color: $darker-2;
  }
  .ms-Icon {
      font-weight: 600;
  }
}

.prev {
  position: absolute;
  top: 50%;
  left: 50px;
  margin-left: 10px;
}

.next {
  position: absolute;
  top: 50%;
  right: 50px;
  margin-right: 10px;
}

.condensed-list {
  overflow: auto;
  width: 100%;
}

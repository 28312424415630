.map-wrapper {
    display: flex;
    height: 100%;
    padding-bottom: 10px;
}

.map {
    background-color: Gainsboro;
    width: 100%;
    height: 100%;
    flex-grow: 1;
}

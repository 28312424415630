.model-info-container {
    margin-top: .5rem;
    margin-bottom: 1rem;
    .model-info-item {
        .title {
            margin-right: 0.5rem;
            font-weight: 500;
        }
    }
}
